import {URL_API} from "../urls";
import axios from "axios";
import { store } from 'react-notifications-component';

export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const GET_RANKING = 'RANKING_VENDAS';
export const NUMS_ORC = 'NUMS_ORC';
export const SEGMENTO = 'SEGMENTO';
export const PERIODO = 'PERIODO';
export const MES = 'MES';
export const AUTENTICAR = 'AUTENTICAR';
export const METAS = 'METAS';
export const CLIENTES_RANKING = 'CLIENTES_RANKING';
export const LISTA_ORCS = 'LISTA_ORCS';
export const LISTA_VENDEDORES = 'LISTA_VENDEDORES';
export const FILTRO_VENDEDOR = 'FILTRO_VENDEDOR';

const ranking = (lista) => {
  return {
    type: GET_RANKING,
    lista
  }
}

const numsOrc = (data) => {
  return {
    type: NUMS_ORC,
    data
  }
}

const listaOrcs = (lista) => {
  return {
    type: LISTA_ORCS,
    lista
  }
}

function getDataInicial(mes, ano) {
  if (mes >= 0) {
    let dtInicial = new Date();
    dtInicial.setMonth(mes);
    dtInicial.setFullYear(ano);
    return dtInicial.getUTCFullYear() + '-' + (dtInicial.getUTCMonth() + 1) + '-' + 1;
  }
  return ano + '-1-1';
}

function getDataFinal(mes, ano) {
  if (mes >= 0) {
    let dtFinal = new Date(2021, mes + 1, 0);
    dtFinal.setMonth(mes);
    dtFinal.setFullYear(ano);
    return dtFinal.getUTCFullYear() + '-' + (dtFinal.getUTCMonth() + 1) + '-' + dtFinal.getDate();
  }
  return ano + '-12-31';
}

export const getRanking = (segmento, mes, ano, vendedorId) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/ranking`, {
      params: {
        segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedorId: vendedorId
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch(ranking(response.data));
    }).catch(error => {
      dispatch(ranking([]));
    });
  }
}

export const getClientesRanking = (segmento, mes, ano, vendedor) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/ranking/clientes`, {
      params: {
        segmento: segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedor: vendedor
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch({
        type: CLIENTES_RANKING,
        lista: response.data
      });
    }).catch(error => {
      // dispatch();
    });
  }
}

export const getOrcInclusos = (segmento, mes, ano, vendedorId) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/orc/inclusos`, {
      params: {
        segmento: segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedorId
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch(listaOrcs(response.data));
    }).catch(error => {
      // dispatch();
    });
  }
}

export const getOrcNegociacao = (segmento, mes, ano, vendedorId) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/orc/negociacao`, {
      params: {
        segmento: segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedorId
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch(listaOrcs(response.data));
    }).catch(error => {
      // dispatch();
    });
  }
}

export const getOrcFecharam = (segmento, mes, ano, vendedorId) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/orc/fecharam`, {
      params: {
        segmento: segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedorId
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch(listaOrcs(response.data));
    }).catch(error => {
      // dispatch();
    });
  }
}

export const getVendedores = (segmento) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/vendedores`, {
      params: {
        segmento: segmento
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch({
        type: LISTA_VENDEDORES,
        lista: response.data
      });
    }).catch(error => {
      // dispatch();
    });
  }
}

export const getNumsOrc = (segmento, mes, ano, vendedorId) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/nums-orc`, {
      params: {
        segmento,
        dtInicial: getDataInicial(mes, ano),
        dtFinal: getDataFinal(mes, ano),
        vendedorId
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch(numsOrc(response.data));
    }).catch(error => {
      dispatch(ranking(null));
    });
  }
}

export const getMetas = (segmento, ano) => {
  return (dispatch) => {
    return axios.get(`${URL_API}/metas`, {
      params: {
        segmento: segmento,
        ano: ano
      },
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      dispatch({
        type: METAS,
        data: response.data
      });
    }).catch(error => {
      // dispatch(ranking(null));
    });
  }
}

export const salvarMetas = (metas) => {
  return (dispatch) => {
    return axios.post(`${URL_API}/metas`, metas, {
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      store.addNotification({
        title: "Metas atualizadas!",
        message: "Os valores das metas mensais dos vendedores foram atualizados!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }).catch(error => {
      store.addNotification({
        title: "Metas não atualizadas",
        message: "Houve um problema ao atualizar os valores das metas mensais.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    });
  }
}

export const atualizarInfoVendas = (arquivos) => {
  return (dispatch) => {
    return axios.post(`${URL_API}/vendas/informacoes`, arquivos, {
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      store.addNotification({
        title: "Informações atualizadas!",
        message: "As informações de vendas foram atualizadas!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }).catch(error => {
      store.addNotification({
        title: "Informações não atualizadas",
        message: "Houve um problema ao atualizar as informações de vendas.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    });
  }
}

export const autenticar = (email, senha) => {
  return (dispatch) => {
    return axios.post(`${URL_API}/autenticar`, {
      email: email,
      senha: senha
    }, {
      headers: {
        'Content-Type': 'application/json',
        'access_token': localStorage.getItem('token')
      }
    }).then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch({type: AUTENTICAR, autenticado: true});
    }).catch(error => {
      dispatch({type: AUTENTICAR, autenticado: false});
    });
  }
}

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('token');
    dispatch({type: AUTENTICAR, autenticado: false});
  }
}

export const mudarSegmento = (segmento) => {
  return (dispatch) => {
    dispatch({
      type: SEGMENTO,
      segmento
    });
  }
}

export const mudarPeriodo = (periodo) => {
  return (dispatch) => {
    dispatch({
      type: PERIODO,
      periodo
    });
  }
}

export const mudarMes = (mes) => {
  return (dispatch) => {
    dispatch({
      type: MES,
      mes
    });
  }
}

export const mudarFiltroVendedor = (vendedorId) => {
  return (dispatch) => {
    dispatch({
      type: FILTRO_VENDEDOR,
      vendedorId
    });
  }
}
