import * as actionTypes from './actions';
import config from './../config';
import {LISTA_ORCS} from "./actions";

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    ranking: [],
    clientesRanking: [],
    listaOrcamentos: [],
    listaVendedores: [],
    metas: [],
    numsOrc: {
        numOrcInclusos: 0,
        numOrcNegociacao: 0,
        numOrcFecharam: 0,
        valorOrcNegociacao: 0,
        valorOrcInclusos: 0
    },
    totalVendas: 0,
    tkm: 0,
    segmento: 'S02',
    filtroVendedor: -1,
    periodo: (new Date()).getFullYear(),
    mes: (new Date()).getMonth(),
    usuarioAutenticado: false
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.GET_RANKING :
            let totalVendas = 0;
            action.lista.forEach(v => {
                totalVendas += v.valor;
            });

            return {
                ...state,
                ranking: action.lista,
                totalVendas: totalVendas
            };
        case actionTypes.NUMS_ORC :
            return {
                ...state,
                numsOrc: action.data
            };
        case actionTypes.SEGMENTO:
            return {
                ...state,
                segmento: action.segmento,
                filtroVendedor: -1,
            };
        case actionTypes.MES:
            return {
                ...state,
                mes: action.mes
            };
        case actionTypes.FILTRO_VENDEDOR:
            return {
                ...state,
                filtroVendedor: action.vendedorId
            };
        case actionTypes.METAS:
            return {
                ...state,
                metas: action.data
            };
        case actionTypes.CLIENTES_RANKING:
            return {
                ...state,
                clientesRanking: action.lista
            };
        case actionTypes.LISTA_ORCS:
            return {
                ...state,
                listaOrcamentos: action.lista
            };
        case actionTypes.LISTA_VENDEDORES:
            return {
                ...state,
                listaVendedores: action.lista
            };
        case actionTypes.AUTENTICAR:
            return {
                ...state,
                usuarioAutenticado: action.autenticado
            };
        default:
            return state;
    }
};

export default reducer;
